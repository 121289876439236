.fixSelectAndDates>div{
  background:rgba(0,0,0,0.05) !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  padding-top:22px !important;
  padding-bottom:2px !important;
  margin-top:0px  !important;
}
.fixSelectAndDates>label{
/* padding-top:10px !important; */
padding-left:16px !important;
}
.fixSelectAndDates>.MuiInputLabel-shrink{
  padding-top:10px !important;
  /* padding-left:16px !important; */
  }

.fixSelectAndDates>div:hover{
  background:rgba(0,0,0,0.13)
}
.fixSelectAndDates>div:before{
  border-width: 1px !important;
}

.fixSelectAndDates .MuiSelect-select{
  padding-left:12px !important;
}
.fixSelectAndDates .MuiSelect-icon{
  margin-top: 0px !important;
  height: 30px;
  width: 30px;
  /* padding-right: 15px; */
  margin-right: 12px;
  fill:#222;
}
.fixSelectAndDates>.Mui-error>.MuiSelect-icon{
  fill:#E41937;
}
header>.MuiToolbar-root{
  padding:15px;
}

.MuiButton-containedPrimary .MuiButton-startIcon>svg{
  fill:white
}
.MuiButton-containedPrimary:hover .MuiButton-startIcon>svg{
  /* fill:#E41937 */
}
.MuiButton-textPrimary:hover >svg{
  fill:white
}
.Mui-error .MuiInputAdornment-positionStart .MuiButtonBase-root svg { fill:#E41937}
.MuiInputAdornment-positionStart .MuiButtonBase-root svg{fill:#222}

.MuiListItem-root.Mui-selected{
  font-weight: bold;
}
.MuiAutocomplete-root > div > label{transform: translate(12px, 24px) scale(1);}

.MuiAutocomplete-endAdornment{
  right:0;
  padding-right:12px;
}
.MuiAutocomplete-endAdornment svg{
  fill:#222 !important;
}
.MuiAutocomplete-popupIndicator svg{
  height: 30px !important;
  width: 30px !important;
}
.MuiFilledInput-underline:after{
  border-color:#222 !important;
}
.MuiTooltip-popper{
  top:-10px !important
}
